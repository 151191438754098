@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Playwrite+HR+Lijeva:wght@100..400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Londrina+Solid:wght@100;300;400;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


.font-outfit {
  font-family: "Outfit", sans-serif;
}
* {
  scroll-behavior: smooth;
}

.text-gradient {
 color: #11101d
  
}

.bg-blue-gradient {
  background: linear-gradient(
    157.81deg,
    #def9fa -43.27%,
    #bef3f5 -21.24%,
    #9dedf0 12.19%,
    #7de7eb 29.82%,
    #5ce1e6 51.94%,
    #33bbcf 90.29%
  );
}

.bg-black-gradient {
  background: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-black-gradient-2 {
  background: linear-gradient(
    -168.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-gray-gradient {
  background: linear-gradient(
    153.47deg,
    rgba(255, 255, 255, 0) -341.94%,
    #14101d 95.11%
  );
}

.bg-discount-gradient {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}

.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.sidebar {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.feature-card:hover {
  background: var(--black-gradient);
  box-shadow: var(--card-shadow);
}

.feedback-container .feedback-card:last-child {
  margin-right: 0px;
}

.feedback-card {
  background: transparent;
}

.feedback-card:hover {
  background: var(--black-gradient);
}

.blue__gradient {
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
  filter: blur(123px);
}

.pink__gradient {
  background: linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%);
  filter: blur(900px);
}

.white__gradient {
  background: rgba(255, 255, 255, 0.6);
  filter: blur(750px);
}
.londrina-solid-thin {
  font-family: "Londrina Solid", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.londrina-solid-light {
  font-family: "Londrina Solid", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.londrina-solid-regular {
  font-family: "Londrina Solid", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.londrina-solid-black {
  font-family: "Londrina Solid", sans-serif;
  font-weight: 900;
  font-style: normal;
}
.londrina-solid-regular-number {
  font-family: 'Londrina Solid', sans-serif;
  font-weight: 300;
  font-style: normal;
}

.number-with-border {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.londrina-solid-regular-number {
  font-family: 'Londrina Solid', sans-serif;
  font-weight: 300;
  font-style: normal;
  color: white;

  text-shadow: 0 0 2px #2483DF, 2px 2px 0 #2483DF, -2px -2px 0 #2483DF, 2px -2px 0 #2483DF, -2px 2px 0 #2483DF;
}
.londrina-solid-regular-faq {
  font-family: 'Londrina Solid', sans-serif;
  font-weight: 300;
  font-style: normal;
  color: white;

  text-shadow: 0 0 2px black, 2px 2px 0 black, -2px -2px 0 black, 2px -2px 0 black, -2px 2px 0 black;
}
/* styles.css or a similar file */
.background-overlay {
  position: relative;
  z-index: 0;
}

.background-overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.3); /* Adjust the opacity as needed */
  z-index: -1;
}
.trapezoid {
  clip-path: polygon(0% 0%, 100% 10%, 90% 100%, 0% 100%);
  border-radius: 15px;
  background-color: yellow;
}
