@import "tailwindcss/tailwind.css";

.planet {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 110px;
  height: 110px;
}

.planet2 {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
  height: 60px;
}

.planet img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.general-planet-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 330px;
  height: 330px;
  z-index: 10;
}

.general-planet {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
}

.orbit-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 650px;
  height: 650px;
  pointer-events: none;
}

.orbit {
  position: absolute;
  border: 2px dotted #2483df;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.orbit-1 {
  animation: rotate-orbit-1 30s linear infinite;
}

.orbit-2 {
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
  animation: rotate-orbit-2 25s linear infinite;
}

.orbit-3 {
  width: 60%;
  height: 60%;
  top: 20%;
  left: 20%;
  animation: rotate-orbit-3 20s linear infinite;
}

.orbit-4 {
  width: 40%;
  height: 40%;
  top: 30%;
  left: 30%;
  animation: rotate-orbit-4 15s linear infinite;
}

.small-circle-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.small-circle {
  animation: rotate-small 30s linear infinite;
  transform-origin: center center;
}

.large-circle-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.large-circle {
  animation: rotate-large 40s linear infinite;
  transform-origin: center center;
}

@keyframes rotate-small {
  0% {
    transform: rotate(calc(var(--i) * 45deg)) translateX(230px)
      rotate(calc(var(--i) * -45deg));
  }
  100% {
    transform: rotate(calc(var(--i) * 45deg + 360deg)) translateX(230px)
      rotate(calc(var(--i) * -45deg - 360deg));
  }
}

@keyframes rotate-large {
  0% {
    transform: rotate(calc(var(--i) * 180deg)) translateX(330px)
      rotate(calc(var(--i) * -180deg));
  }
  100% {
    transform: rotate(calc(var(--i) * 180deg + 360deg)) translateX(330px)
      rotate(calc(var(--i) * -180deg - 360deg));
  }
}

@keyframes rotate-orbit-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-orbit-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-orbit-3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-orbit-4 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1200px) {
  .planet {
    width: 60px;
    height: 60px;
  }
  .planet2 {
    width: 40px;
    height: 40px;
  }
  .general-planet-container {
    width: 400px;
    height: 400px;
  }
  .orbit-container {
    width: 600px;
    height: 600px;
  }
  .small-circle-container {
    width: 60px;
    height: 80px;
  }
  .large-circle-container {
    width: 80px;
    height: 80px;
  }

  @keyframes rotate-small {
    0% {
      transform: rotate(calc(var(--i) * 45deg)) translateX(210px)
        rotate(calc(var(--i) * -45deg));
    }
    100% {
      transform: rotate(calc(var(--i) * 45deg + 360deg)) translateX(210px)
        rotate(calc(var(--i) * -45deg - 360deg));
    }
  }

  @keyframes rotate-large {
    0% {
      transform: rotate(calc(var(--i) * 180deg)) translateX(290px)
        rotate(calc(var(--i) * -180deg));
    }
    100% {
      transform: rotate(calc(var(--i) * 180deg + 360deg)) translateX(290px)
        rotate(calc(var(--i) * -180deg - 360deg));
    }
  }
}

@media (max-width: 992px) {
  .planet {
    width: 70px;
    height: 70px;
  }
  .planet2 {
    width: 50px;
    height: 50px;
  }
  .general-planet-container {
    width: 300px;
    height: 300px;
  }
  .orbit-container {
    width: 650px;
    height: 650px;
  }
  .small-circle-container {
    width: 50px;
    height: 70px;
  }
  .large-circle-container {
    width: 60px;
    height: 60px;
  }

  @keyframes rotate-small {
    0% {
      transform: rotate(calc(var(--i) * 45deg)) translateX(220px)
        rotate(calc(var(--i) * -45deg));
    }
    100% {
      transform: rotate(calc(var(--i) * 45deg + 360deg)) translateX(220px)
        rotate(calc(var(--i) * -45deg - 360deg));
    }
  }

  @keyframes rotate-large {
    0% {
      transform: rotate(calc(var(--i) * 180deg)) translateX(310px)
        rotate(calc(var(--i) * -180deg));
    }
    100% {
      transform: rotate(calc(var(--i) * 180deg + 360deg)) translateX(310px)
        rotate(calc(var(--i) * -180deg - 360deg));
    }
  }
}

@media (max-width: 768px) {
  .planet {
    width: 50px;
    height: 50px;
  }
  .planet2 {
    width: 30px;
    height: 30px;
  }
  .general-planet-container {
    width: 230px;
    height: 230px;
  }
  .orbit-container {
    width: 500px;
    height: 500px;
  }
  .small-circle-container {
    width: 40px;
    height: 60px;
  }
  .large-circle-container {
    width: 50px;
    height: 50px;
  }

  @keyframes rotate-small {
    0% {
      transform: rotate(calc(var(--i) * 45deg)) translateX(160px)
        rotate(calc(var(--i) * -45deg));
    }
    100% {
      transform: rotate(calc(var(--i) * 45deg + 360deg)) translateX(160px)
        rotate(calc(var(--i) * -45deg - 360deg));
    }
  }

  @keyframes rotate-large {
    0% {
      transform: rotate(calc(var(--i) * 180deg)) translateX(230px)
        rotate(calc(var(--i) * -180deg));
    }
    100% {
      transform: rotate(calc(var(--i) * 180deg + 360deg)) translateX(230px)
        rotate(calc(var(--i) * -180deg - 360deg));
    }
  }
}

@media (max-width: 576px) {
  .planet {
    width: 30px;
    height: 30px;
  }
  .planet2 {
    width: 25px;
    height: 25px;
  }
  .general-planet-container {
    width: 130px;
    height: 130px;
  }
  .orbit-container {
    width: 350px;
    height: 350px;
  }
  .small-circle-container {
    width: 30px;
    height: 50px;
  }
  .large-circle-container {
    width: 40px;
    height: 40px;
  }

  @keyframes rotate-small {
    0% {
      transform: rotate(calc(var(--i) * 45deg)) translateX(100px)
        rotate(calc(var(--i) * -45deg));
    }
    100% {
      transform: rotate(calc(var(--i) * 45deg + 360deg)) translateX(100px)
        rotate(calc(var(--i) * -45deg - 360deg));
    }
  }

  @keyframes rotate-large {
    0% {
      transform: rotate(calc(var(--i) * 180deg)) translateX(155px)
        rotate(calc(var(--i) * -180deg));
    }
    100% {
      transform: rotate(calc(var(--i) * 180deg + 360deg)) translateX(155px)
        rotate(calc(var(--i) * -180deg - 360deg));
    }
  }
}
